const {
  REACT_APP_API,
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL,
  REACT_APP_FILEAPI,
  REACT_APP_GUARD,
  REACT_APP_MPMS_TOKEN,
  REACT_APP_URL,
} = process.env;

const config = {
  // api: "https://lab.testing.muntra.com/api",
  api: "https://api.testing.muntra.com/api",
  errorNotificationApi:
    "https://app.testing.muntra.com/log-api/error-notifications",
  fileapi: "https://fileapitest.muntra.se/api",
  guard: "dentallab",
  mpmsToken: "YZmwhJ9UU7LiTL6dsixP91HlG57TYtXpSwrldupmjKykX5a82qy6Tza1WGn9i1we",
  url: "http://localhost:3000",
};

const api = REACT_APP_API || window.ENV?.REACT_APP_API || config.api;
const errorNotificationApi =
  REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  window.ENV?.REACT_APP_ERROR_NOTIFICATION_SERVICE_URL ||
  config.errorNotificationApi;
const fileapi =
  REACT_APP_FILEAPI || window.ENV?.REACT_APP_FILEAPI || config.fileapi;
const guard = REACT_APP_GUARD || window.ENV?.REACT_APP_GUARD || config.guard;
const mpmsToken =
  REACT_APP_MPMS_TOKEN || window.ENV?.REACT_APP_MPMS_TOKEN || config.mpmsToken;
const url = REACT_APP_URL || window.ENV?.REACT_APP_URL || config.api;

export default {
  api,
  errorNotificationApi,
  fileapi,
  guard,
  mpmsToken,
  url,
};
